import React from "react";
import { useHistory } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import CameraIcon from "@material-ui/icons/PhotoCamera";
import ScanIcon from "@material-ui/icons/CenterFocusWeak";

export function VinHistory({ vinHistory, match }) {
  const history = useHistory();

  function renderHistory() {
    const historyItems = (vinHistory || []).slice(0);
    historyItems.reverse();

    return historyItems.map(h => (
      <ListItem key={h.date}>
        <ListItemAvatar>
          <Avatar>
            <CameraIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={h.vin} secondary={h.date.toLocaleString()} />
      </ListItem>
    ));
  }

  function handleScanClick() {
    history.push("/scan");
  }

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6">WebScanner</Typography>
          <div style={{ flex: 1 }} />
          <IconButton onClick={handleScanClick} edge="end" color="inherit" aria-label="scan">
              <ScanIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <List>{renderHistory()}</List>
    </div>);
}
